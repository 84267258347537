body {
  margin: 0;
  cursor: crosshair;
  background: black;
  background-color: black;
}

.container {
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
}

a {
  text-decoration: underline;
  color: white;
}

.react-terminal-wrapper {
  background: blue !important;
  color: white !important;
  height: 800px !important;
  width: 1100px !important;
  padding: 2em !important;
  margin: auto;
}

.react-terminal-wrapper:after {
  top: 12px !important;
  color: white !important;
}

.react-terminal {
  height: 100% !important;
  width: 100% !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-terminal::-webkit-scrollbar {
  display: none !important;
}

.ascii {
  font-size: 1em;
}

.mobile-ascii {
  display: none;
}

.underline {
  text-decoration: underline;
}

.website-pic {
  height: 12em;
}

.menu-option {
  cursor: cell;
  text-decoration: none !important;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.grey-dollar {
  color: #a2a2a2;
}

.wrap {
  white-space: normal !important;
  text-indent: -2em;
}

.album-art {
  height: 12rem;
}

@media only screen and (max-width: 1068px) {
  .react-terminal-wrapper {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
  }

  .react-terminal-window-buttons {
    display: none !important;
  }

  .react-terminal {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }

  .react-terminal-wrapper:after {
    display: none !important;
  }

  .ascii {
    display: none;
  }

  .mobile-ascii {
    display: block;
    width: 100%;
    margin-top: -1.5rem;
  }

  .react-terminal-line {
    max-width: 95vw !important;
    margin-left: 2vw !important;
  }
}


/* mobile layout */
@media only screen and (max-width: 738px) {
  .ascii {
    display: none;
  }



  .react-terminal-wrapper {
    padding: 0 0 0.1rem 0 !important;
    max-width: 100vw !important;
    height: 100vh !important;
    word-wrap: break-word;
    overflow-x: hidden !important;
    border-radius: 0 !important;
  }

  .react-terminal {
    margin: 0 auto 0 auto !important;
    width: 345px !important;
    height: 99vh !important;
    overflow-x: hidden !important;
  }

  .react-terminal-line {
    max-width: 90vw !important;
    word-wrap: break-word;
    margin-left: 0 !important;
  }

  input {
    pointer-events: none;
    display: none;
    font-size: 16px;
  }

  .website-pic {
    width: 100% !important;
    max-width: 100vw !important;
  }

  .react-terminal-wrapper:after {
    display: none !important;
  }

  .mobile-ascii {
    display: block;
    width: 100%;
    margin-top: -1.5rem;
  }
}